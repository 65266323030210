'use client'

import { IconMidDot } from 'src/assets/icons/IconMiddot'
import { pxToRem } from '@ui/style/muiTheme'
import UIText from '@components/ui/UIText'
import { Box, useTheme } from '@mui/joy'
import { SxProps } from '@mui/joy/styles/types'

export type ContentType =
	| 'LIVE'
	| 'LIVEEND'
	| 'RECENT'
	| 'POPULAR'
	| 'RECOMMENDED'
	| 'POLLING'
	| 'POLLEND'
	| 'EXCLUSIVE'
	| 'PROGRAMEND'
export interface ContentBadgeProps {
	contentType: ContentType
	sx?: SxProps
}

/**
 * ContentBadge 컴포넌트
 * 피그마 시안 상의 Badge 컴포넌트를 분류에 상관없이 나타내는 컴포넌트입니다.
 * 필요한 Badge의 타입을 입력해서 노출합니다.
 * @param props.contentType - 필요한 Badge의 타입을 입력합니다. (default 'LIVE')
 * @props 'LIVE'(라이브) | 'LIVEEND'(라이브 종료) | 'RECENT'(NOW) | 'POPULAR'(인기) | 'RECOMMENDED'(추천) | 'POLLING'(진행 중인 Poll) | 'POLLEND'(종료된 Poll) | 'EXCLUSIVE'(회원전용) | 'PROGRAMEND' (종영 프로그램)
 *
 */
const ContentBadge = ({ contentType = 'LIVE', sx }: ContentBadgeProps) => {
	const { color } = useTheme()
	let badgeContent: string | React.ReactNode
	switch (contentType) {
		case 'LIVE':
			badgeContent = (
				<>
					<IconMidDot />
					<span>LIVE</span>
				</>
			)
			break
		case 'LIVEEND':
			badgeContent = (
				<>
					<IconMidDot color="#aaa" />
					<span>LIVE</span>
				</>
			)
			break
		case 'RECENT':
			badgeContent = 'NOW'
			break
		case 'POPULAR':
			badgeContent = '인기'
			break
		case 'RECOMMENDED':
			badgeContent = '추천'
			break
		case 'POLLING':
			badgeContent = '진행 중'
			break
		case 'POLLEND':
			badgeContent = '종료'
			break
		case 'EXCLUSIVE':
			badgeContent = '회원전용'
			break
		case 'PROGRAMEND':
			badgeContent = '종영'
			break
		default:
			badgeContent = ''
	}
	const colorStyles = () => {
		let bgColorValue: string = color.colExclusiveRed
		let textColorValue: string = color.colWhite
		let borderColorValue: string | undefined
		switch (contentType) {
			case 'LIVE':
				bgColorValue = color.colExclusiveRed
				break
			case 'LIVEEND':
				bgColorValue = '#444'
				textColorValue = '#aaa'
				break
			case 'RECENT':
				bgColorValue = color.colPink
				break
			case 'POPULAR':
				bgColorValue = color.colPurple
				break
			case 'RECOMMENDED':
				bgColorValue = color.colBlue
				break
			case 'POLLING':
				bgColorValue = color.colLightBlue
				textColorValue = color.colDarkBlue
				break
			case 'POLLEND':
				bgColorValue = color.colGray2
				textColorValue = color.colGray5
				break
			case 'EXCLUSIVE':
				bgColorValue = 'transparent'
				textColorValue = color.colDarkBlue
				borderColorValue = color.colDarkBlue
				break
			case 'PROGRAMEND':
				bgColorValue = color.colGray3
				textColorValue = color.colGray5
				break
			default:
				bgColorValue = color.colExclusiveRed
		}
		return {
			whiteSpace: 'nowrap',
			color: textColorValue,
			background: bgColorValue,
			border: borderColorValue && `1px solid ${borderColorValue}`,
			padding: borderColorValue
				? `${pxToRem(5)} ${pxToRem(6)}`
				: `${pxToRem(6)} ${pxToRem(7)}`,
		}
	}
	return (
		<Box display="inline-flex" borderRadius={pxToRem(4)} sx={{ ...colorStyles(), ...sx }}>
			<UIText
				component="p"
				display="flex"
				alignItems="center"
				fontSize={pxToRem(12)}
				lineHeight={pxToRem(12)}
				fontWeight="700"
				color="inherit"
				sx={{ '&> *:not(:first-child)': { marginLeft: pxToRem(4) } }}
			>
				{badgeContent}
			</UIText>
		</Box>
	)
}
export default ContentBadge
