'use client'

import { useCallback } from 'react'
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

interface CookieHeaderState {
	cookieHeader?: string
}

export const cookieHeaderState = atom<CookieHeaderState>({
	key: '#cookieHeader',
	default: {
		cookieHeader: undefined,
	},
})

export const useCookieHeaderControl = () => {
	const [state, setState] = useRecoilState(cookieHeaderState)

	const setcookieHeader = useCallback(
		(value?: string) => {
			if (value) {
				setState({ cookieHeader: value })
			} else {
				setState({ cookieHeader: undefined })
			}
		},
		[setState],
	)

	return { cookieHeader: state.cookieHeader, setcookieHeader }
}

export const useSetCookieHeader = () => {
	const setState = useSetRecoilState(cookieHeaderState)
	return (value?: string) => {
		if (value) {
			setState({ cookieHeader: value })
		} else {
			setState({ cookieHeader: undefined })
		}
	}
}

export const useGetCookieHeader = () => {
	const state = useRecoilValue(cookieHeaderState)
	return state.cookieHeader
}
