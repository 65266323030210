export const IconVideoView = ({ className }: SVGComponentProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none"
			className={className}
		>
			<rect width="22" height="22" rx="4" fill="black" fillOpacity="0.5" />
			<path d="M11 6H6V11M16 11V16H11" stroke="white" strokeWidth="1.5" />
		</svg>
	)
}
