// [HOT, AD, PICK, 단독, 속보, 기획, 특집]
export type BulletCode = 'HOT' | 'AD' | 'PICK' | 'SINGLE' | 'NEWSFLASH' | 'PLAN' | 'SPECIAL'
export type BulletName = 'HOT' | 'AD' | 'PICK' | '단독' | '속보' | '기획' | '특집'
/**
 * @description BulletName에 따른 색상을 반환
 * @param type BulletName (string)
 * @returns 색상 (string)
 */
function getBulletColor(type: BulletName): string {
	const defaultColor = '#0098DB'
	const bulletColorMap: Record<BulletName, string> = {
		HOT: '#0098DB',
		AD: '#888888',
		PICK: '#DB0007',
		단독: '#DB0007',
		속보: '#DB0007',
		기획: '#0098DB',
		특집: '#0098DB',
	}

	return bulletColorMap[type] || defaultColor
}

export default getBulletColor
