import UILink, { UILinkProps } from '@components/ui/UILink'
import Box, { BoxProps as MuiBoxProps } from '@mui/joy/Box'
import { theme } from '@ui/style'
import { Property } from 'csstype'
import { ParsedUrlQueryInput } from 'querystring'
import { MouseEventHandler, ReactNode } from 'react'

interface ContentCardBoxProps {
	href?: string
	link?: UILinkProps['link']
	query?: ParsedUrlQueryInput
	flexDirection?: MuiBoxProps['flexDirection']
	layoutGap?: MuiBoxProps['gap']
	layoutSx?: MuiBoxProps['sx']
	linkSepareted?: boolean
	isDark?: boolean
	onClick?: MouseEventHandler<HTMLAnchorElement>
	children: ReactNode | ReactNode[]
}
const ContentCardBox = ({
	href,
	link,
	query,
	flexDirection,
	layoutGap,
	layoutSx,
	linkSepareted,
	isDark,
	onClick,
	children,
}: ContentCardBoxProps) => {
	return linkSepareted ? (
		<Box
			display="flex"
			color="initial"
			flexDirection={flexDirection ?? 'column'}
			alignItems="stretch"
			sx={{
				'.thumb': {
					...Object.keys(theme.breakpoints.values).reduce((acc, breakpoint) => {
						const direction =
							typeof flexDirection === 'object'
								? (
										flexDirection as {
											[key: string]: Property.FlexDirection | undefined
										}
								  )[breakpoint]
								: (flexDirection as Property.FlexDirection)

						if (direction === undefined) return acc

						return {
							...acc,
							[theme.breakpoints.up(
								breakpoint as keyof typeof theme.breakpoints.values,
							)]: {
								marginRight: direction === 'row' ? layoutGap : 0,
								marginLeft: direction === 'row-reverse' ? layoutGap : 0,
								marginBottom: direction === 'column' ? layoutGap : 0,
								marginTop: direction === 'column-reverse' ? layoutGap : 0,
							},
						}
					}, {}),
				},
				...layoutSx,
				[theme.mediaQueries.hover]: {
					'&:hover .info-title': {
						color: isDark ? theme.color.colGray3 : theme.color.colGray5,
					},
				},
			}}
		>
			{children}
		</Box>
	) : (
		<Box
			display="flex"
			color="initial"
			flexDirection={flexDirection}
			alignItems="stretch"
			sx={{
				'.thumb': {
					...Object.keys(theme.breakpoints.values).reduce((acc, breakpoint) => {
						const direction =
							typeof flexDirection === 'object'
								? (
										flexDirection as {
											[key: string]: Property.FlexDirection | undefined
										}
								  )[breakpoint]
								: (flexDirection as Property.FlexDirection)

						if (direction === undefined) return acc

						return {
							...acc,
							[theme.breakpoints.up(
								breakpoint as keyof typeof theme.breakpoints.values,
							)]: {
								marginRight: direction === 'row' ? layoutGap : 0,
								marginLeft: direction === 'row-reverse' ? layoutGap : 0,
								marginBottom: direction === 'column' ? layoutGap : 0,
								marginTop: direction === 'column-reverse' ? layoutGap : 0,
							},
						}
					}, {}),
				},
				...layoutSx,
				[theme.mediaQueries.hover]: {
					'&:hover .info-title': {
						color: isDark ? theme.color.colGray3 : theme.color.colGray5,
					},
				},
			}}
			{...{ component: UILink, href }}
			onClick={onClick}
			query={query}
		>
			{children}
		</Box>
	)
}

export default ContentCardBox
