import { useTheme } from '@mui/joy'

export const IconMidDot = ({ color }: SVGComponentProps) => {
	const theme = useTheme()
	const iconColor = color || theme.color.colWhite
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="5" height="6" viewBox="0 0 5 6" fill="none">
			<circle cx="2.5" cy="3" r="2.5" fill={iconColor} />
		</svg>
	)
}
