'use client'

import { SVGProps } from 'react'
import { styled } from '@mui/joy'

interface IconBookmarkProps extends SVGProps<SVGSVGElement> {
	isBookmarked?: boolean
	size?: 'md' | 'lg'
	color?: 'primary' | 'secondary' | 'dark'
}

const StyledIconBookmark = styled('svg')<IconBookmarkProps>(
	({ theme, isBookmarked, color = 'primary' }) => {
		const colorMap = {
			primary: {
				stroke: {
					default: theme.color.colGray5,
					hover: theme.color.colDarkBlue,
					bookmarked: theme.color.colDarkBlue,
					bookmarkedHover: theme.color.colPointBlue,
				},
				fill: {
					default: 'transparent',
					hover: 'transparent',
					bookmarked: theme.color.colDarkBlue,
					bookmarkedHover: theme.color.colPointBlue,
				},
			},
			secondary: {
				stroke: {
					default: theme.color.colBlack,
					hover: theme.color.colDarkBlue,
					bookmarked: theme.color.colDarkBlue,
					bookmarkedHover: theme.color.colPointBlue,
				},
				fill: {
					default: 'transparent',
					hover: 'transparent',
					bookmarked: theme.color.colDarkBlue,
					bookmarkedHover: theme.color.colPointBlue,
				},
			},
			dark: {
				stroke: {
					default: theme.color.colWhite,
					hover: theme.color.colWhite,
					bookmarked: theme.color.colPointBlue,
					bookmarkedHover: theme.color.colPointBlue,
				},
				fill: {
					default: 'transparent',
					hover: 'transparent',
					bookmarked: theme.color.colPointBlue,
					bookmarkedHover: theme.color.colPointBlue,
				},
			},
		}

		const styles = {
			cursor: 'pointer',
			stroke: isBookmarked
				? colorMap[color].stroke.bookmarked
				: colorMap[color].stroke.default,
			fill: isBookmarked ? colorMap[color].fill.bookmarked : colorMap[color].fill.default,
			[theme.mediaQueries.hover]: {
				'&:hover': {
					stroke: isBookmarked
						? colorMap[color].stroke.bookmarkedHover ||
						  colorMap[color].stroke.bookmarked
						: colorMap[color].stroke.hover,
					fill: isBookmarked
						? colorMap[color].fill.bookmarkedHover || colorMap[color].fill.bookmarked
						: colorMap[color].fill.hover,
				},
			},
		}

		return styles
	},
)

const sizeConfig = {
	boxSize: {
		md: 16,
		lg: 32,
	},
	strokeWidth: {
		md: 1.5,
		lg: 1.8,
	},
	path: {
		md: 'M2.5 1.5H13.5V14.5L8 11.7143L2.5 14.5V1.5Z',
		lg: 'M10 8H22V24L16 20.0976L10 24V8Z',
	},
}

export const IconBookmark = ({ isBookmarked, color, size = 'md', ...props }: IconBookmarkProps) => (
	<StyledIconBookmark
		isBookmarked={isBookmarked}
		color={color}
		xmlns="http://www.w3.org/2000/svg"
		width={sizeConfig.boxSize[size]}
		height={sizeConfig.boxSize[size]}
		viewBox={`0 0 ${sizeConfig.boxSize[size]} ${sizeConfig.boxSize[size]}`}
		strokeWidth={sizeConfig.strokeWidth[size]}
		fill="currentColor"
		stroke="currentColor"
		{...props}
	>
		<path d={sizeConfig.path[size]} />
	</StyledIconBookmark>
)
