import { useCallback } from 'react'
import { atom, useRecoilState } from 'recoil'

interface AuthState {
	auth?: boolean
}

export const authState = atom<AuthState>({
	key: '#auth',
	default: {
		auth: false,
	},
})

export const useAuthControl = () => {
	const [state, setState] = useRecoilState(authState)

	const setAuth = useCallback(
		(value?: boolean) => {
			if (value) {
				setState({ auth: value })
			} else {
				setState({ auth: false })
			}
		},
		[setState],
	)

	return { auth: state.auth, setAuth }
}
