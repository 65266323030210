import UIText from '@components/ui/UIText'
import getBulletColor, { BulletName } from '@utils/getBulletColor'

interface ContentBulletProps {
	bullet?: BulletName
}
const ContentBullet = ({ bullet }: ContentBulletProps) => {
	return (
		bullet && (
			<UIText marginRight="0.3em" color={getBulletColor(bullet)}>
				{bullet}
			</UIText>
		)
	)
}
export default ContentBullet
