'use client'

import { ReactNode } from 'react'
import { styled } from '@mui/joy/styles'
import MuiIconButton, { IconButtonProps as MuiIconButtonProps } from '@mui/joy/IconButton'
import { useDarkModeControl } from '@hooks/useDarkModeControl'
import { theme } from '@ui/style'
import { GAProps } from '@utils/handleGAEvent'

export interface UIIconButtonState extends Omit<Partial<MuiIconButtonProps>, 'children' | 'color'> {
	children?: ReactNode | ReactNode[]
	color?: 'neutral' | 'dark'
	ga?: GAProps
}
const UIIconButtonRoot = styled(MuiIconButton)(
	({ ownerState }: { ownerState: UIIconButtonState }) => {
		const { darkMode } = useDarkModeControl()
		const { variant, color, size, disabled } = ownerState
		const colorValue = color || 'neutral'
		const colorMap = {
			neutral: {
				background: {
					default: 'transparent',
					hover: theme.color.colGray1,
				},
			},
			dark: {
				background: {
					default: 'transparent',
					hover: 'rgba(255,255,255,0.15)',
				},
			},
		}
		const styles = {
			borderRadius: '4px',
			background: colorMap[colorValue].background.default,
			'&:active, &[aria-expanded="true"]': {
				background: darkMode ? '#303030' : colorMap[colorValue].background.hover,
			},
			'&:hover': {
				background: colorMap[colorValue].background.default,
			},
			[theme.mediaQueries.hover]: {
				'&:hover': {
					background: darkMode ? '#303030' : colorMap[colorValue].background.hover,
				},
			},
		}
		return styles
	},
)

export default UIIconButtonRoot
