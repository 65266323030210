import { ReactNode } from 'react'
import { ParsedUrlQueryInput } from 'querystring'
import Box, { BoxProps as MuiBoxProps } from '@mui/joy/Box'
import UILink from '@components/ui/UILink'

interface ContentInfoBoxProps {
	href?: string
	query?: ParsedUrlQueryInput
	infoGap?: MuiBoxProps['gap']
	infoSx?: MuiBoxProps['sx']
	children: ReactNode | ReactNode[]
}
const ContentInfoBox = ({ href, query, infoGap, infoSx, children }: ContentInfoBoxProps) => {
	return href ? (
		<Box
			className="content-info-box"
			display="flex"
			flexDirection="column"
			alignItems="stretch"
			flexGrow="1"
			textAlign="left"
			sx={{
				'&>*:not(:first-child)': {
					marginTop: infoGap,
				},
				...infoSx,
			}}
			{...{ component: UILink, href }}
			query={query}
		>
			{children}
		</Box>
	) : (
		<Box
			className="content-info-box"
			display="flex"
			flexDirection="column"
			alignItems="stretch"
			flexGrow="1"
			textAlign="left"
			sx={{
				'&>*:not(:first-child)': {
					marginTop: infoGap,
				},
				...infoSx,
			}}
		>
			{children}
		</Box>
	)
}

export default ContentInfoBox
