'use client'

import { MouseEvent, forwardRef, useCallback } from 'react'
import UIIconButtonRoot, { UIIconButtonState } from './UIIconButtonRoot'
import handleGAEvent from '@utils/handleGAEvent'

const UIIconButton = forwardRef<HTMLButtonElement, UIIconButtonState>(
	(
		{
			className,
			variant,
			color,
			size,
			disabled,
			children,
			tabIndex,
			loading,
			loadingIndicator,
			sx,
			ga,
			onClick,
			...props
		},
		ref,
	) => {
		const handleClick = useCallback(
			(e: MouseEvent<HTMLButtonElement>) => {
				if (ga) {
					handleGAEvent(ga)
				}
				onClick?.(e)
			},
			[ga, onClick],
		)
		return (
			<UIIconButtonRoot
				ref={ref}
				className={className}
				variant={variant}
				size={size}
				disabled={disabled}
				tabIndex={tabIndex}
				loading={loading}
				loadingIndicator={loadingIndicator}
				sx={sx}
				ownerState={{ variant, color, size, disabled }}
				onClick={handleClick}
				{...props}
			>
				{children}
			</UIIconButtonRoot>
		)
	},
)

export default UIIconButton
