import { ApiResultVoBoolean } from '@schemas/auth'
import { fetchAPI } from '@utils/http/apiRequest'

export type ScrapReqModel = {
	articleIdx?: string
}

export type ScrapResponse = CommonResponse<ApiResultVoBoolean>

const path = '/restapi/v1/post/article/scrap'
const queryKeyString = ['@article/scrap'] as const

const getQuery = async (props: ScrapReqModel, cookieHeader?: string) => {
	const { articleIdx } = props
	const params = new URLSearchParams({
		...(articleIdx ? { articleIdx: String(articleIdx) } : {}),
	})
	return fetchAPI({ path, params, method: 'POST', cookieHeader })
}

export const queryScrap = (props: ScrapReqModel, cookieHeader?: string) => ({
	queryKey: [...queryKeyString],
	queryFn: () => getQuery(props, cookieHeader),
})

export const articleScrapMutationProps = (cookieHeader?: string) => ({
	mutationKey: [queryKeyString],
	mutationFn: (args?: ScrapReqModel) => getQuery({ articleIdx: args?.articleIdx }, cookieHeader),
})
