'use client'

import { useMutation } from '@tanstack/react-query'
import { ScrapReqModel, articleScrapMutationProps } from '.'
import { useGetCookieHeader } from '@store/cookieHeader'

export const useArticleScrapMutation = () => {
	const cookieHeader = useGetCookieHeader()
	const { data, ...rest } = useMutation<CommonResponse<boolean>, Error, ScrapReqModel>(
		articleScrapMutationProps(cookieHeader),
	)
	return { data, ...rest }
}
