'use client'

import { usePathname } from 'next/navigation'
import { useEffect } from 'react'
import { atom, useRecoilState, useResetRecoilState } from 'recoil'

export interface HeaderStates {
	megaMenuOpen: boolean
	brandMenuOpen: boolean
	searchOpen: boolean
	alarmOpen: boolean
	settingOpen: boolean
}

const initialHeaderStates: HeaderStates = {
	megaMenuOpen: false,
	brandMenuOpen: false,
	searchOpen: false,
	alarmOpen: false,
	settingOpen: false,
}

export const headerStatesAtom = atom<HeaderStates>({
	key: 'headerStates',
	default: initialHeaderStates,
})

export const headerRefAtom = atom<React.RefObject<HTMLElement> | null>({
	key: 'headerRef',
	default: null,
})

export const useHeaderStates = () => useRecoilState<HeaderStates>(headerStatesAtom)
export const useResetRecoilHeaderStates = () => useResetRecoilState(headerStatesAtom)
export const useHeaderRef = () => useRecoilState(headerRefAtom)

export const HeaderStateManager = () => {
	const resetHeaderStates = useResetRecoilHeaderStates()
	const pathname = usePathname()

	// pathname이 바뀌면 초기값으로 리셋
	useEffect(() => {
		resetHeaderStates()
	}, [pathname, resetHeaderStates])

	return null
}

export const useSetHeaderState = (stateName: keyof HeaderStates, value: boolean) => {
	const [, setHeaderStates] = useHeaderStates()
	return () =>
		setHeaderStates((prevStates) => ({
			...prevStates,
			[stateName]: value,
		}))
}

export const useOpenHeaderState = (stateName: keyof HeaderStates) => {
	return useSetHeaderState(stateName, true)
}

export const useCloseHeaderState = (stateName: keyof HeaderStates) => {
	return useSetHeaderState(stateName, false)
}

export const useToggleHeaderState = (stateName: keyof HeaderStates) => {
	const [, setHeaderStates] = useHeaderStates()
	return () =>
		setHeaderStates((prevStates) => ({
			...prevStates,
			[stateName]: !prevStates[stateName],
		}))
}

export const useToggleSingleHeaderState = (stateName: keyof HeaderStates) => {
	const [, setHeaderStates] = useHeaderStates()
	return () => {
		setHeaderStates((prevStates) => {
			const newStates: HeaderStates = { ...prevStates }
			Object.keys(newStates).forEach((key) => {
				newStates[key as keyof HeaderStates] =
					key === stateName ? !prevStates[stateName] : false
			})
			return newStates
		})
	}
}
