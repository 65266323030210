'use client'

import useModal from '@components/layout/Modal'
import UIIconButton from '@components/ui/UIIconButton'
import UIText from '@components/ui/UIText'
import { useNotification } from '@lib/notification'
import IconButton from '@mui/joy/IconButton'
import { ApiResultVoBoolean } from '@schemas/auth'
import { useArticleScrapMutation } from '@services/api/Article/Scrap/mutation'
import { useAuthControl } from '@store/auth'
import pxToRem from '@utils/pxToRem'
import { MouseEvent, useEffect, useState } from 'react'
import { IconBookmark } from 'src/assets/icons/IconBookmark'

interface ScrapButtonProps {
	articleIdx?: string
	isScrap?: boolean
	isDark?: boolean
	large?: boolean
	onChange?: (value: boolean) => void
	onSuccess?: () => void
}
const ScrapButton = ({
	articleIdx,
	isScrap,
	isDark,
	large,
	onChange,
	onSuccess,
}: ScrapButtonProps) => {
	const { auth } = useAuthControl()
	const confirm = useModal()
	const { notify } = useNotification()
	const [scrap, setScrap] = useState<boolean>()
	const { mutateAsync: scrapMutate } = useArticleScrapMutation()

	const handleScrap = async (e: MouseEvent) => {
		e.preventDefault()
		e.stopPropagation()
		e.nativeEvent.stopImmediatePropagation()

		if (auth) {
			const result: ApiResultVoBoolean = await scrapMutate({ articleIdx })
			if (result?.data) {
				setScrap(!scrap)
				onChange?.(!scrap)
				if (!scrap) {
					notify('기사가 스크랩 되었습니다.')
				} else {
					notify('스크랩 해제되었습니다.')
				}
				onSuccess?.()
			}
		} else {
			confirm.login()
		}
	}
	useEffect(() => {
		setScrap(isScrap)
	}, [isScrap])
	return large ? (
		<UIIconButton
			onClick={handleScrap}
			color={isDark ? 'dark' : 'neutral'}
			sx={{ padding: `0 ${pxToRem(2)}` }}
		>
			<UIText readonly>{scrap ? '스크랩 해제' : '스크랩'}</UIText>
			<IconBookmark
				isBookmarked={scrap ?? isScrap}
				size="lg"
				color={isDark ? 'dark' : 'secondary'}
			/>
		</UIIconButton>
	) : (
		<IconButton
			onClick={handleScrap}
			sx={(theme) => ({
				minWidth: 0,
				minHeight: 0,
				padding: 0,
				borderRadius: 0,
				width: pxToRem(24),
				height: pxToRem(24),
				margin: pxToRem(-4),
				'&:hover, &:active': {
					backgroundColor: 'transparent',
				},
			})}
		>
			<UIText readonly>{scrap ? '스크랩 해제' : '스크랩'}</UIText>
			<IconBookmark
				className="info-bookmark"
				isBookmarked={scrap ?? isScrap}
				color={isDark ? 'dark' : 'primary'}
			/>
		</IconButton>
	)
}

export default ScrapButton
