'use client'

import ScrapButton from '@components/button/ScrapButton'
import UIFlex from '@components/ui/UIFlex'
import UIText from '@components/ui/UIText'
import color from '@ui/style/color.theme'
import { pxToRem } from '@ui/style/muiTheme'
import { ContentProps, InfoConfigProps } from '..'
import ContentBullet from './ContentBullet'
import ContentInfoBox from './ContentInfoBox'

export interface ContentInfoProps
	extends InfoConfigProps,
		Pick<
			ContentProps,
			| 'articleIdx'
			| 'title'
			| 'bullet'
			| 'bulletColor'
			| 'body'
			| 'additionalText'
			| 'href'
			| 'query'
			| 'showBookmark'
			| 'isBookmarked'
		> {
	onScrapSuccess?: () => void
}
const ContentInfo = ({
	articleIdx,
	infoGap = pxToRem(8),
	titleLevel = 'HeadingL',
	bodyLevel = 'Footnote',
	title,
	titleClamp = 2,
	bullet,
	bulletColor = 'blue',
	body,
	bodyClamp = 4,
	additionalText,
	isDark,
	infoSx,
	showBody,
	showAdditionalText,
	showBookmark,
	isBookmarked,
	href,
	query,
	onScrapSuccess,
}: ContentInfoProps) => {
	return (
		<ContentInfoBox infoGap={infoGap} infoSx={infoSx} href={href} query={query}>
			{/* 불렛, 타이틀 */}
			<UIText
				lineClamp={titleClamp}
				level={titleLevel}
				color={isDark ? color.colWhite : color.colBlack}
				className="info-title"
				sx={{ wordBreak: 'break-all' }}
			>
				<ContentBullet bullet={bullet} />
				{title}
			</UIText>
			{/* 본문 */}
			{showBody && body && (
				<UIText
					level={bodyLevel}
					lineClamp={bodyClamp}
					color={isDark ? color.colWhite : color.colGray5}
					className="info-body"
					sx={{ wordBreak: 'break-all' }}
				>
					{body}
				</UIText>
			)}
			{showAdditionalText && additionalText && (
				<UIFlex
					width="100%"
					flexDirection="row"
					alignItems="center"
					className="info-additionalBox"
				>
					{/* 추가 정보 : 작성시간, 작성자, 종영일 등 */}
					<UIText
						className="info-additionalText"
						color={isDark ? color.colWhite : color.colGray5}
						level="CaptionTime"
						flexGrow="1"
						sx={{ opacity: isDark ? 0.6 : 1 }}
						suppressHydrationWarning
					>
						{additionalText}
					</UIText>
					{showBookmark && (
						<ScrapButton
							articleIdx={articleIdx}
							isScrap={isBookmarked}
							isDark={isDark}
							onSuccess={onScrapSuccess}
						/>
						// <IconBookmark className="info-bookmark" isBookmarked={isBookmarked} />
					)}
				</UIFlex>
			)}
		</ContentInfoBox>
	)
}

export default ContentInfo
